import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { map } from "rxjs";
import { UserPermissions } from "src/app/modules/user/models/user-permissions";
import { AuthService } from "src/app/services/auth.service";

export function requireAnyRole(...roles: UserPermissions[]): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    return authService.getLogin().pipe(
      map(() => authService.hasPermission(roles))
    );
  }
}

export function requireAllRoles(...roles: UserPermissions[]): CanActivateFn {
  return () => {
    const authService = inject(AuthService);
    return authService.getLogin().pipe(
      map(() => authService.hasPermission(roles, true))
    );
  }
}